import React, { useState, useEffect, useRef  } from "react";
import global from '../../assets/scss/global.module.scss'
import capcha from '../../assets/images/capcha-icon.svg';

const Captcha = ({ onValidate }) => {
  const [captchaText, setCaptchaText] = useState("");
  const [inputValue, setInputValue] = useState("");

  const generateCaptcha = () => {
    const randomString = Math.random().toString(36).substring(2, 8); 
    setCaptchaText(randomString);
  };

  useEffect(() => {
    generateCaptcha(); 
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    if (inputValue.toLowerCase() === captchaText.toLowerCase()) {
      onValidate(true);
    } else {
      onValidate(false);
      generateCaptcha(); 
    }
  };

  return (
        <>
         <div className={global.row}>
             <div className={ `${global.col_s_4} ${global.capcha_font_size}`} >
             {captchaText} <button onClick={generateCaptcha} style={{ background: "none", border: "none", cursor: "pointer" }}>
                <img src={capcha} alt="refresh icon" />
          </button>
            </div>
            <div className={global.col_s_6}>
            <input type="text" className={global.form_control}    value={inputValue}  onChange={handleInputChange}  placeholder="Enter CAPTCHA" />  
           </div>
        </div>
      </>
  );
};

export default Captcha;
