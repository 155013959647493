
import React, { useRef, useState } from 'react';
import logo from '../../../assets/images/tramo-logo.svg';
import global from '../../../assets/scss/global.module.scss'
import useronboard from './UserOnboard.module.scss';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import UserAddharCard from './UserAddharCard';
//import UserPanCard from './UserPanCard';
import UserGstin from './UserGstin';




const UserOnboardStepper = () => {

    const stepperRef = useRef(null);


    return (
        <>
            <div className={global.top_header}>
                <div className={global.container}>
                    <h1><img src={logo} alt="Tramo" /> </h1>
                </div>
            </div>

            <div className={useronboard.bg_color}>
                <div className={global.container}>


                    <div className={useronboard.complete_registration}>
                        <h2>Completing Your Registration</h2>


                        <div className="card flex justify-content-center">
                            <Stepper ref={stepperRef} style={{ flexBasis: '50rem' }}>
                                <StepperPanel header="Personal Information ">
                                    <div className="flex flex-column h-12rem">
                                        <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                                            
                                            <UserAddharCard />
                                            <div className={useronboard.pan_details}  >
                                                <h3><i className={useronboard.gray_text}>B</i>  Pan Verification </h3>
                                            </div>
                                            {/* <UserPanCard/> */}

                                            </div>
                                    </div>
                                    
                                </StepperPanel>
                                <StepperPanel header="Business Information">
                                    <div className="flex flex-column h-12rem">
                                        <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                                            <UserGstin />
                                        </div>
                                    </div>
                                    
                                </StepperPanel>
                                <StepperPanel header="Upload KYC Document">
                                    <div className="flex flex-column h-12rem">
                                        <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">Content III</div>
                                    </div>
                                
                                </StepperPanel>
                                <StepperPanel header="Banking Information">
                                    <div className="flex flex-column h-12rem">
                                        <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">Content IV</div>
                                    </div>
                                    
                                </StepperPanel>
                                <StepperPanel header="Department  Information">
                                    <div className="flex flex-column h-12rem">
                                        <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">Content v</div>
                                    </div>
                                
                                </StepperPanel>
                            </Stepper>
                        </div>


                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default UserOnboardStepper