
import React, { useState } from 'react';
import person_image from '../../../assets/images/image14.png';
import global from '../../../assets/scss/global.module.scss'
import useronboard from './UserOnboard.module.scss';

const UserAddharCard = () => {


  return (
    <>
      <div className={useronboard.confirm_details}>
        <div className={`${global.row} ${useronboard.confirm_details_heading}`}>
          <div className={global.col_s_11}>
            <h3>  <i className={useronboard.text_number}>A</i> Aadhaar Verification </h3>
          </div>
          <div className={`${global.col_s_1} ${global.pos_rel}`}>
            <i className={global.verification_icon}></i>
            <i className={global.verify_success_icon}></i>
          </div>
        </div>

        <div className={useronboard.confirm_details_form}>

          <div className={global.row}>
            <div className={global.col_s_5}>
              <div className={`${global.form_group} ${global.mar_top_16}`}>
                <label>Enter your Aadhaar Number</label>
                <div className={global.d_flex}>
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4} `} maxLength="1" type='text' />
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4}`} maxLength="1" type='text' />
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4}`} maxLength="1" type='text' />
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_16}`} maxLength="1" type='text' />

                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4} `} maxLength="1" type='text' />
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4}`} maxLength="1" type='text' />
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4}`} maxLength="1" type='text' />
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_16}`} maxLength="1" type='text' />

                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4} `} maxLength="1" type='text' />
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4}`} maxLength="1" type='text' />
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4}`} maxLength="1" type='text' />
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4}`} maxLength="1" type='text' />
                </div>
                <div className={`${global.error_message} ${global.text_right} `}>Incorrect Aadhar number entered. Please re-enter</div>
              </div>
            </div>
          </div>
          <div className={useronboard.otp_message_}>
            OTP will be sent to your Aadhaar Linked Phone number.
          </div>

          <div className={global.row}>
            <div className={global.col_s_5}>
              <div className={`${global.form_group} ${global.mar_top_16}`}>
                <div className={` ${useronboard.aadhar_otp_number} ${global.mar_bottom_16}`}> <span className={useronboard.mobile_number}>9205  0844  1754</span>    <a href="#" className={useronboard.change_mobile_number}>Change Aadhaar Number </a></div>
                <label className={global.mar_bottom_16}>Enter OTP sent to Aadhaar linked Number.</label>
                <div className={global.d_flex}>
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4} `} maxLength="1" type='text' />
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4}`} maxLength="1" type='text' />
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4}`} maxLength="1" type='text' />
                  <input className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_16}`} maxLength="1" type='text' />
                </div>
                <div className={useronboard.otp_message_}>Did't receive any OTP? <a href="#">Resend in 23s</a> <a href="#" className={useronboard.resend_otp}>Resend OTP </a> </div>
              </div>
            </div>
          </div>


          <div className={global.row}>
            <div className={global.col_s_5}>
              <span className={global.success_message}> <i className={global.verify_success_icon}></i> Aadhaar Verified</span>
            </div>
          </div>

          <div className={global.image_preview_details}>

            <div className={`${global.row} ${global.mar_top_16}`}>
              <div className={global.col_s_3}>
                <div className={global.images_bg}>
                  <img src={person_image} />
                </div>
              </div>
              <div className={global.col_s_9}>
                <div className={global.row}>
                  <div className={global.col_s_3}>
                    Name :
                  </div>
                  <div className={global.col_s_9}>
                    dfdsfsdf
                  </div>
                </div>

                <div className={`${global.row} ${global.mar_top_8}`}>
                  <div className={global.col_s_3}>
                    DOB         :
                  </div>
                  <div className={global.col_s_9}>
                    23rd of March 1980
                  </div>
                </div>

                <div className={`${global.row} ${global.mar_top_8}`}>
                  <div className={global.col_s_3}>
                    Gender    :
                  </div>
                  <div className={global.col_s_9}>
                    Male
                  </div>
                </div>

                <div className={`${global.row} ${global.mar_top_8}`}>
                  <div className={global.col_s_3}>
                    Address  :
                  </div>
                  <div className={global.col_s_9}>
                    Tramo Technolabs Pvt ltd, noida UP 201301
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={useronboard.submit_text_bg_}>
          <input type='submit' className={`${global.submit_seconday_btn} ${global.display_inline_blk}`} value="Send OTP" />
          <input type='submit' className={`${global.submit_primary_btn} ${global.display_inline_blk}`} value="Send OTP" />
        </div>
      </div>
    </>
  );
}
export default UserAddharCard