export const PageUrls = {
    TICKET_DETAIL: '/ticket/ticket-detail/:id',
    UNAUTHORIZED: '/unauthorized',
    INDEX: '/',
    LOGIN: '/login',
    SIGN_UP: '/signup',
    USER_REGISTRATION: '/user-register',
    USER_ONBOARD: '/user-onboard'
};

export const ApiUrls = {
    LEAD_BASIC_REGISTRATION: '/leads/createlead',
    LEAD_GENRATE_OTP: '/leads/otp/generate',
    LEAD_VERIFY_OTP: '/leads/otp/verify',
}

export const PageRouteRegx = {
    TICKET_DETAIL: '/ticket/ticket-detail/[0-9]',
};

export const AUTHORITY = {
    USER: 'USER',
    ADMIN: 'ADMIN'
};

export const AUTHORITY_ROUT_MAP = [
    { route_regx: PageRouteRegx.TICKET_DETAIL, authority: [AUTHORITY.USER, AUTHORITY.ADMIN] },
];