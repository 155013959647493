import React, { useState } from 'react';
import global from '../../assets/scss/global.module.scss'
import UserRegistrations from './UserRegistrations.module.scss';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import fetcher from '../../utils/fetcher';
import { ApiUrls, PageUrls } from '../../utils/constants';

const EmailOTP = ({ emailOTPEvent }) => {
  const [otpState, setOtpState] = useState('otp'); // Tracks OTP state
  const [errorMsg, setErrorMsg] = useState('');

  // Handles OTP input
  const handleOtpChange = (value, index) => {
    const newOtp = [...formik.values.otp];
    newOtp[index] = value;
    formik.setFieldValue('otp', newOtp);
  };

  // Handle OTP submission
  const handleOtpSubmit = async () => {
  //submit email otp
          //if status 200 then show success email Dialog
          const otpArr=formik.values.otp.toString().replaceAll(",","");
    const requestData = {
      identifier: "test@mail.com",  // Replace with the actual identifier (e.g., phone number)
      otp: otpArr  // Replace with the actual OTP value
    };
    //submit otp
    //if status 200 then show success Dialog
    const resOtp = await fetcher.post(ApiUrls.LEAD_VERIFY_OTP, requestData);
    if (resOtp?.status === 200) {
      setOtpState('success');
    } else if(resOtp?.status !== 200){
      setErrorMsg(resOtp.message);
    }else{
      setOtpState('failure');
    }
  };

  const otpValidationSchema = Yup.object({
    otp: Yup.array()
      .of(Yup.string().matches(/^[0-9]{1}$/, 'Must be a digit').required('6 Digit opt required.'))
      .min(6, 'OTP must be exactly 6 digits')
      .max(6, 'OTP must be exactly 6 digits'),
  });

  const formik = useFormik({
    initialValues: {
      otp: ['', '', '', '', '', ''],
    },
    validationSchema: otpValidationSchema,
    onSubmit: handleOtpSubmit
  });

  const handleFailedSuccessState = () => {
    const isSuccess = true;

    if (isSuccess) {
      emailOTPEvent();
    } else {
      //failedCase
    }
   
  };

  return (
    <>
    <div className={global.overlay}>
      <div className={UserRegistrations.opt_screen}>
        {otpState === 'otp' && (
          <>
            <h2>Enter Email OTP</h2>
            <div className={`${global.mar_top_16} ${global.text_center}`}>
              Verification code sent to <span className={UserRegistrations.blue_text}>itssumit34@gmail.com</span>
            </div>
            
            {/* OTP Input Fields */}
            <form onSubmit={formik.handleSubmit}>
              <div className={`${global.row} ${global.d_flex} ${global.d_flex_center} ${global.mar_top_16}`}>
                {formik.values.otp.map((_, index) => (
                  <span key={index} className={`${global.col_s_2} ${UserRegistrations.otp_right_1}`}>
                    <input
                      className={global.form_control}
                      type="text"
                      maxLength="1"
                      inputMode="numeric"
                      value={formik.values.otp[index]}
                      onChange={(e) => handleOtpChange(e.target.value, index)}
                    />
                  </span>
                ))}
              </div>

              {/* Show validation error for the entire OTP */}
              {formik.errors.otp && formik.touched.otp ? (
                <div className={`${global.error_message} ${global.text_right} ${global.mar_top_8}`}>
                  Please enter a valid 6-digit OTP.
                </div>
              ) : errorMsg ? (
                <div className={`${global.error_message} ${global.text_right} ${global.mar_top_8}`}>
                  {errorMsg}
                </div>
              ) : null}

              <div className={`${global.form_group} ${global.text_left} ${global.mar_top_8}`}>
                Didn’t receive any OTP? <a href='#'>Resend OTP</a>
              </div>

              <div className={global.form_group}>
                <input type="submit" value="Verify" className={global.submit_primary_btn} />
              </div>
            </form>
          </>
        )}

        {/* Unsuccessful State */}
        {otpState === 'failure' && (
          <div className={UserRegistrations.unsuccessful}>
            <div className={global.text_center}>
              <i className={global.unsuccessful_icon}></i>
            </div>
            <h2>Unsuccessful!</h2>
            <div className={`${global.mar_top_16} ${global.mar_bottom_16} ${global.text_center}`}>
              You've reached the maximum number of OTP attempts. Please try again in 24 hours.
            </div>
            <div className={`${global.form_group} ${global.mar_top_8}`}>
              <input type="submit" value="Back to Home" className={global.submit_primary_btn} onClick={handleFailedSuccessState}/>
            </div>
          </div>
        )}
{/* Successful State */}
        {otpState === 'success' && (
         <div className={UserRegistrations.successful}>
         <div className={global.text_center}>
           <i className={global.successful_icon}></i>
         </div>
         <h2>Successful Sign Up!</h2>
         <div className={`${global.mar_top_16} ${global.mar_bottom_16} ${global.text_center}`}>
           Thank you for choosing us. We have sent you a link on email and phone to complete your onboarding process with us. Kindly check your inbox or click here to complete the process.
         </div>
         <div className={`${global.form_group} ${global.mar_top_8}`}>
           <input type="submit" value="Back to Home" className={global.submit_primary_btn} onClick={handleFailedSuccessState}/>
         </div>
       </div>       
        )}
      </div>
    </div>
    </>
  )
}

export default EmailOTP