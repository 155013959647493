
import React, { useState } from 'react';
import logo from '../../../assets/images/tramo-logo.svg';
import global from '../../../assets/scss/global.module.scss'
import formmodule from '../../../assets/scss/form.module.scss'
import useronboard from './UserOnboard.module.scss';
//import WithGstNumber from './WithGstNumber';
//import WithOutGstNumber from './WithOutGstNumber';

const UserGstin = () => {

  const [isDisabled, setIsDisabled] = useState(true);

  const toggleDisable = () => {
    setIsDisabled(!isDisabled);
  };

  const [selectedOption, setSelectedOption] = useState('option1');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };


 
  return (
    <>
        <div className={`${useronboard.confirm_details} ${useronboard.confirm_details} ${global.mar_top_16}`}>
                  <div className={`${global.row} ${useronboard.confirm_details_heading}`}>
                    <div className={global.col_s_11}>
                      <h3>  <i className={useronboard.text_number}>C</i> GSTIN Information </h3>
                    </div>
                    <div className={`${global.col_s_1} ${global.pos_rel}`}>
                      <i className={global.verification_icon}></i>
                      <i className={global.verify_success_icon}></i>
                    </div>
                  </div>

                  <div className={useronboard.confirm_details_form}>
                    <h3>Do you want to continue...</h3>
                    <div className={global.row}>
                      <div className={global.col_s_6}>
                        <div className={`${global.form_group} ${global.mar_top_16} ${global.form_group_gray_bor}`}>
                          <label className={formmodule.radio_button}>
                            <i className={formmodule.label_text}>We are GSTIN registered</i>
                            <input type="radio" value="option1" checked={selectedOption === 'option1'}
                              onChange={handleOptionChange} name="radio" />
                            <span className={formmodule.checkmark}></span>
                          </label>
                        </div>
                      </div>


                      <div className={global.col_s_6}>
                        <div className={`${global.form_group} ${global.mar_top_16} ${global.form_group_gray_bor}`}>
                          <label className={formmodule.radio_button}>
                            <i className={formmodule.label_text}>Continue without GST Number</i>
                            <input type="radio" value="option2" checked={selectedOption === 'option2'}
                              onChange={handleOptionChange} name="radio" />
                            <span className={formmodule.checkmark}></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    {selectedOption === 'option1' && (
                      <>
                        <div className={global.row}>
                          <div className={global.col_s_6}>
                            <div className={global.row}>
                              <div className={global.col_s_8}>
                                <div className={`${global.form_group}`}>
                                  <label>GSTIN Number <a href="">Change GSTIN Number </a></label>
                                  <input className={global.form_control} placeholder='Enter Your GSTIN no.' type='text' />
                                  <div className={`${global.error_message} ${global.text_right} `}>Invalid GSTIN entered. Please re-enter</div>

                                </div>
                              </div>
                              <div className={global.col_s_4}>
                                <div className={`${global.form_group}`}>
                                  <label>&nbsp;</label>
                                  <input type='submit' className={`${global.submit_seconday_btn} ${global.d_block}`} value="Submit" />
                                  {/* <input type='submit' className={`${global.submit_primary_btn} ${global.display_inline_blk}`} value="Submit" /> */}
                                </div>
                              </div>
                            </div>

                            <div className={global.row}>
                              <div className={global.col_s_8}>
                                <div className={`${global.form_group}`}>
                                  <label>Business Incorporation Date</label>
                                  <input className={global.form_control} placeholder='DD/MM/YYYY' type='text' />
                                  <div className={`${global.error_message} ${global.text_right} `}>This field is required*.</div>

                                </div>
                              </div>
                            </div>

                          </div>

                          <div className={global.col_s_6}>
                            <div className={`${global.form_group}`}>
                              <label>Business PAN</label>
                              <input className={global.form_control} placeholder='Enter business PAN number' type='text' />
                            </div>
                          </div>
                        </div>


                        <h3 className={useronboard.border_line}>
                          Fetched Data</h3>

                        <div className={global.row}>
                          <div className={global.col_s_6}>
                            <div className={`${global.form_group}`}>
                              <label>Business Name</label>
                              <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Tramo' type='text' />
                            </div>

                            <div className={`${global.form_group}`}>
                              <label>Constitution type</label>
                              <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Sole Proprietorship' type='text' />
                            </div>

                            <div className={global.row}>
                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>
                                  <label>Registration Date</label>
                                  <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Registration Date' type='text' />
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Status</label>
                                  <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Status' type='text' />
                                </div>
                              </div>
                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>
                                  <label>State Code</label>
                                  <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='State Code' type='text' />
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Centre</label>
                                  <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Centre' type='text' />
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className={global.col_s_6}>
                            <div className={`${global.form_group}`}>
                              <label>Business Registered Address 1</label>
                              <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Business Registered Address 1' type='text' />
                            </div>

                            <div className={`${global.form_group}`}>
                              <label>Business Registered Address 2</label>
                              <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Business Registered Address 2' type='text' />
                            </div>

                            <div className={global.row}>
                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>

                                  <label>Pin Code</label>
                                  <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Pin Code' type='text' />

                                </div>
                              </div>
                            </div>

                            <div className={global.row}>
                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>
                                  <label>City/Village/Town</label>
                                  <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='City/Village/Town' type='text' />
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>District </label>
                                  <input className={`${global.form_control} ${global.disbled_bg}`} disabled={isDisabled} placeholder='District ' type='text' />
                                </div>
                              </div>
                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>
                                  <label>State jurisdiction</label>
                                  <input className={`${global.form_control} ${global.disbled_bg}`} disabled={isDisabled} placeholder='State jurisdiction' type='text' />
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Country</label>
                                  <input className={`${global.form_control} ${global.disbled_bg}`} disabled={isDisabled} placeholder='Country' type='text' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </>
                    )}


                    {selectedOption === 'option2' && (
                      <>
                        <div className={global.row}>
                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>
                                  <label>Business Name</label>
                                  <input className={global.form_control} placeholder='Business Name' type='text' />
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Constitution type</label>
                                  <select name="" id="" className={global.form_control}>
                                      <option value="Constitution">Constitution</option>
                                      <option value="Constitution">Constitution</option>
                                      <option value="Constitution">Constitution</option>
                                      <option value="Constitution">Constitution</option>
                                    </select>
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Business Incorporation Date</label>
                                  <input className={global.form_control} placeholder='23/07/2018' type='text' />
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Business Plan</label>
                                  <input className={global.form_control} placeholder='AAGTC8771J' type='text' />
                                </div>

                              </div>

                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>
                                  <label>Business Registered Address 1</label>
                                  <input className={global.form_control} placeholder='Business Registered Address 1' type='text' />
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Business Registered Address 2</label>
                                  <input className={global.form_control} placeholder='Business Registered Address 2' type='text' />
                                </div>

                                <div className={global.row}>
                                      <div className={global.col_s_6}>
                                      <div className={`${global.form_group}`}>
                                        <label>Pin Code</label>
                                        <input className={global.form_control} placeholder='Business Registered Address 1' type='text' />
                                      </div>
                                      </div>
                                </div>  

                                <div className={global.row}>
                                      <div className={global.col_s_6}>
                                      <div className={`${global.form_group}`}>
                                        <label>City/Village/Town</label>
                                        <input className={global.form_control} placeholder='City/Village/Town' type='text' />
                                      </div>
                                      </div>

                                      <div className={global.col_s_6}>
                                      <div className={`${global.form_group}`}>
                                        <label>City/Village/Town</label>
                                        <input className={global.form_control} placeholder='City/Village/Town' type='text' />
                                      </div>
                                      </div>
                                </div> 

                                <div className={global.row}>
                                      <div className={global.col_s_6}>
                                      <div className={`${global.form_group}`}>
                                        <label>State jurisdiction</label>
                                        <select name="" id="" className={global.form_control}>
                                          <option value="Delhi">Delhi</option>
                                      </select>
                                      </div>
                                      </div>

                                      <div className={global.col_s_6}>
                                      <div className={`${global.form_group}`}>
                                        <label>Country</label>
                                        <select name="" id="" className={global.form_control}>
                                      <option value="India">India</option>
                                    </select>
                                      </div>
                                      </div>
                                </div> 


                              </div>
                            </div>
                      </>
                    )}

                  </div>
                  <div className={useronboard.submit_text_bg_}>
                    <div className={global.row}>
                      <div className={global.col_s_2}>
                        <input type='submit' className={`${global.submit_primary_btn} ${global.display_inline_blk}`} value="Submit" />
                      </div>
                    </div>
                  </div>
                </div>

                 
      {/* <WithGstNumber /> */}
       {/* <WithOutGstNumber />  */}
    </>
  );
}
export default UserGstin