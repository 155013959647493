import React from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { canAccessTheRouteWithUserAuthorities, isAuthenticated, isRestrictedRoutWithAuthority, setRedirectLoginCallback } from "../utils/authGuard";
import Layout from "../components/layout/Layout";  // Import the Layout HOC
import Login from "../pages/login/Login";
import Index from "../pages/Index";
import { NoMatch } from "../pages/NoMatch";
import { PageUrls } from "../utils/constants";

import UserOnboard from "../pages/user-registration/user-onboard/UserOnboard";
//import UserOnboard from "../pages/user-registration/user-onboard/UserOnboard";
import UserOnboardStepper from "../pages/user-registration/user-onboard/UserOnboardStepper";
import UserRegistration from "../pages/user-registration/UserRegistration";



function PrivateRoute({ element }) {
  const location = useLocation();
  if (!isAuthenticated()) {
    return  <Navigate to={PageUrls.LOGIN} />;
  }
  if (isRestrictedRoutWithAuthority(location.pathname) && !canAccessTheRouteWithUserAuthorities(location.pathname)) {
    return <Navigate to={PageUrls.UNAUTHORIZED} />
  }
  return element;
}

const Routers = () => {
    const navigate = useNavigate();
    setRedirectLoginCallback(() => {
      navigate(PageUrls.LOGIN);
    });
    
    return(
        <Routes>
          <Route path={PageUrls.LOGIN} element={<Login />}/>
          <Route path={PageUrls.INDEX} element={<Index />}/>
          <Route path={PageUrls.USER_REGISTRATION} element={<UserRegistration />}/> 
          <Route path={PageUrls.USER_ONBOARD} element={<UserOnboardStepper />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
    )
}

export default Routers;